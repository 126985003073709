.hd-toggleButton {
    --toggle-button-selected-background: var(--hd-color-neutral-surface-weak);
    --toggle-button-hover-background: var(--hd-color-neutral-surface-weak);
    --toggle-button-color: var(--hd-color-neutral-icon);

    background-color: transparent;
    color: var(--toggle-button-color);
    border-width: 0;
    border-radius: var(--hd-border-radius-sm);
    display: flex;
    gap: var(--hd-space-2);
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    min-width: var(--hd-space-4);

    &:has(> span:empty) {
        aspect-ratio: 1/1;
    }

    &[data-hovered] {
        cursor: pointer;
        background-color: var(--toggle-button-hover-background);
    }

    &:has(> span:not(:empty)) {
        &[data-hovered] {
            background-color: transparent;
        }
    }

    &[data-selected] {
        background-color: var(--toggle-button-selected-background);
    }

    &[data-focused] {
        outline: none;
    }

    &[data-focus-visible] {
        box-shadow: var(--hd-focus-ring);
    }

    > span:empty {
        display: none;
    }
}
